var _0x10f4 = ['z2v0vgLTzq==', 'ww91CIbSAwnLBNnLigv4CgLYzwqGB24G', 'zgf0yq==', 'uhjVz3jLC3mG', 'AgvmshG=', 'u2vLia==', 'ChjVzhvJDenVzgvZ', 'C2nYAxb0s2v5', 'D3rmuhm=', 'rKjcwKG=', 'tM8GBgLJzw5ZzsbMB3vUzc4k', 'CgfJA2fNzu5HBwu=', 'qvfYAMq=', 'CgfYC2u=', 'ChjVzhvJDhm=', 'igzVCIbTB3jLigLUzM9YBwf0Aw9UlGO=', 'sgr5sMW=', 'rg9iAwe=', 'zxDJr2C=', 'Du9wCuK=', 'z2v0rNvSBfLLyxi=', 'lcb0AguGCgfJA2fNzsb3yxmGChvIBgLZAgvKig9Uia==', 'C2v0', 'zxrNsxC=', 'C29YDa==', 'EeXMBLu=', 'AgfZ', 's0XeCe8=', 'rM1Ttxy=', 'tNvstvG=', 'ww91CIb0CMLHBcbSAwnLBNnLigv4CgLYzwqGB24G', 'z2v0rgf0zq==', 'BgvUz3rO', 'txPHwe8=', 'BgLJzw5Zzuv4CgLYyxrPB25eyxrL', 'DhjPywW=', 'vejxq3K=', 'r2Ltv1q=', 'uhPWBva=', 'z2v0', 'vgHLihbYB2r1y3qGAxmGBM90igLUy2X1zgvKigLUihrOzsbSAwnLBNnLlGO=', 'z2v0tw9UDgG=', 's2DnDfK=', 'DMvYC2LVBG==', 'rKj6sum=', 'DgLTzxn0yw1W', 'B2jQzwn0', 'DhLWzq==', 'BgLJzw5ZAw5Nrg9JC1vYBa==', 'BMfTzq==', 'DwTXAeq=', 'zg9JC1vYBa==', 'z3jVDxbfBMq=', 'z3jVDxa=', 'yuDyzhy=', 'zMLUza==', 'D2fYBG==', 'zNvUy3rPB24=', 'Dw5KzwzPBMvK', 'ChjVzhvJDe5HBwu=', 'y29Uy2f0', 'ChvIBgLZAerHDgu=', 'zxHWAxj5rgf0zq==', 'CeDhAfu=', 'y29Kzq==', 'Dg9mB2nHBgvtDhjPBMC=', 'tgLJzw5ZzsbHy3rPDMf0Aw9UigzHAwXLzcbMB3iG'];
(function (_0x50aaef, _0x10f4b0) {
  var _0x145713 = function (_0x421c90) {
    while (--_0x421c90) {
      _0x50aaef['push'](_0x50aaef['shift']());
    }
  };
  _0x145713(++_0x10f4b0);
})(_0x10f4, 0x1b7);
var _0x1457 = function (_0x50aaef, _0x10f4b0) {
  _0x50aaef = _0x50aaef - 0x0;
  var _0x145713 = _0x10f4[_0x50aaef];
  if (_0x1457['aWziBr'] === undefined) {
    var _0x421c90 = function (_0x1aecb4) {
      var _0x16aa36 = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=',
        _0x27d11b = String(_0x1aecb4)['replace'](/=+$/, '');
      var _0x46c2e8 = '';
      for (var _0x29c8dd = 0x0, _0x5ea27f, _0x25dc8b, _0xcce210 = 0x0; _0x25dc8b = _0x27d11b['charAt'](_0xcce210++); ~_0x25dc8b && (_0x5ea27f = _0x29c8dd % 0x4 ? _0x5ea27f * 0x40 + _0x25dc8b : _0x25dc8b, _0x29c8dd++ % 0x4) ? _0x46c2e8 += String['fromCharCode'](0xff & _0x5ea27f >> (-0x2 * _0x29c8dd & 0x6)) : 0x0) {
        _0x25dc8b = _0x16aa36['indexOf'](_0x25dc8b);
      }
      return _0x46c2e8;
    };
    _0x1457['XjGMHe'] = function (_0x2c6af5) {
      var _0x1b5a14 = _0x421c90(_0x2c6af5);
      var _0x2181c6 = [];
      for (var _0x4e0ecf = 0x0, _0x1a2fc4 = _0x1b5a14['length']; _0x4e0ecf < _0x1a2fc4; _0x4e0ecf++) {
        _0x2181c6 += '%' + ('00' + _0x1b5a14['charCodeAt'](_0x4e0ecf)['toString'](0x10))['slice'](-0x2);
      }
      return decodeURIComponent(_0x2181c6);
    }, _0x1457['XQgCdR'] = {}, _0x1457['aWziBr'] = !![];
  }
  var _0x1b62be = _0x1457['XQgCdR'][_0x50aaef];
  return _0x1b62be === undefined ? (_0x145713 = _0x1457['XjGMHe'](_0x145713), _0x1457['XQgCdR'][_0x50aaef] = _0x145713) : _0x145713 = _0x1b62be, _0x145713;
};
var context = {
    data: '  {\"timestamp\":1732030758,\"products\":[{\"trial\":false,\"code\":\"KENDOUIREACT\",\"licenseExpirationDate\":1765971430},{\"trial\":false,\"code\":\"KENDOUICOMPLETE\",\"licenseExpirationDate\":1765971430},{\"trial\":false,\"code\":\"KENDOUIVUE\",\"licenseExpirationDate\":1765971430},{\"trial\":false,\"code\":\"KENDOUIANGULAR\",\"licenseExpirationDate\":1765971430}],\"integrity\":\"f+azbQJt/Bkqotb8o2fTMmdQHYY=\"}  '
  },
  cache = new Map();
function setScriptKey(_0x35d548) {}
function validatePackage(_0x401145) {
  if (cache[_0x1457('0x38')](_0x401145[_0x1457('0xc')])) {
    if (_0x1457('0x3a') !== _0x1457('0x3a')) {
      function _0x40b483() {
        _0x55416e[_0x1457('0x10')](_0x394293);
      }
    } else return cache[_0x1457('0x2')](_0x401145[_0x1457('0xc')]);
  }
  var _0x13003c = _0x401145[_0x1457('0x6')] ? '\x20v' + _0x401145[_0x1457('0x6')] : '',
    _0x487465 = _0x401145[_0x1457('0xc')] + _0x13003c,
    _0x5ac69b = JSON[_0x1457('0x2b')](context[_0x1457('0x20')]),
    _0x12f1b9 = !_0x5ac69b[_0x1457('0x25')] && !_0x5ac69b[_0x1457('0x8')],
    _0x404bc6 = _0x5ac69b[_0x1457('0x25')] && typeof KendoLicensing === _0x1457('0x15'),
    _0x21bd47 = matchProduct(_0x5ac69b, _0x401145[_0x1457('0x24')]),
    _0x379487;
  if (_0x12f1b9 || _0x404bc6) {
    if (_0x1457('0x2a') === _0x1457('0x2f')) {
      function _0x32c728() {
        var _0x210aed = _0x1457('0x21')[_0x1457('0x17')](_0x1ec4d4[_0x1457('0x16')]),
          _0x5a1943 = typeof _0x5077fd[_0x1457('0x10')] === _0x1457('0x14');
        _0x5a1943 ? _0x1cbf22[_0x1457('0x10')](_0x210aed) : _0x52c64a[_0x1457('0x13')](_0x210aed), _0x19add1[_0x1457('0x13')](_0x2bf2c1(_0x2b5d62)), _0x5a1943 && _0x210919[_0x1457('0xf')]();
      }
    } else _0x379487 = {
      'type': 0x0,
      'packageName': _0x487465,
      'docsUrl': _0x401145[_0x1457('0xb')]
    };
  } else {
    if (_0x1457('0x39') === _0x1457('0x39')) {
      if (!_0x21bd47) {
        if (_0x1457('0x1a') !== _0x1457('0x1a')) {
          function _0x118e65() {
            return _0x388bef[_0x1457('0x38')](_0x14b8a6[_0x1457('0x1b')]);
          }
        } else _0x379487 = {
          'type': 0x1,
          'packageName': _0x487465,
          'docsUrl': _0x401145[_0x1457('0xb')]
        };
      } else {
        if (_0x21bd47[_0x1457('0x41')] === !![] && isExpired(getCurrentDate(), _0x21bd47[_0x1457('0x40')])) {
          if (_0x1457('0x0') === _0x1457('0x0')) _0x379487 = {
            'type': 0x3,
            'packageName': _0x487465,
            'expiryDate': parseDate(_0x21bd47[_0x1457('0x40')]),
            'docsUrl': _0x401145[_0x1457('0xb')]
          };else {
            function _0x2acfd0() {
              return new _0x538141(_0x6547c3 * 0x3e8);
            }
          }
        } else {
          if (isExpired(_0x401145[_0x1457('0x18')], _0x21bd47[_0x1457('0x40')])) {
            if (_0x1457('0x22') !== _0x1457('0x22')) {
              function _0x54738d() {
                _0xff686d += _0x1457('0x28');
              }
            } else _0x379487 = {
              'type': 0x2,
              'packageName': _0x487465,
              'publishDate': parseDate(_0x401145[_0x1457('0x18')]),
              'expiryDate': parseDate(_0x21bd47[_0x1457('0x40')]),
              'docsUrl': _0x401145[_0x1457('0xb')]
            };
          }
        }
      }
    } else {
      function _0x45a77a() {
        _0x4e0ecf = {
          'type': 0x0,
          'packageName': _0x1a2fc4,
          'docsUrl': _0x281109[_0x1457('0xb')]
        };
      }
    }
  }
  if (_0x379487 && typeof console === _0x1457('0x9')) {
    if (_0x1457('0x5') !== _0x1457('0x5')) {
      function _0x27a0d3() {
        _0x23dc27[_0x1457('0xf')]();
      }
    } else {
      var _0x3e0d1b = _0x1457('0x21')[_0x1457('0x17')](_0x401145[_0x1457('0x16')]),
        _0x225671 = typeof console[_0x1457('0x10')] === _0x1457('0x14');
      if (_0x225671) {
        if (_0x1457('0x2e') === _0x1457('0x2e')) console[_0x1457('0x10')](_0x3e0d1b);else {
          function _0x1c2d92() {
            if (!_0x3e4ff8) _0x37b223 = {
              'type': 0x1,
              'packageName': _0x36e726,
              'docsUrl': _0x3f80b8[_0x1457('0xb')]
            };else {
              if (_0x15d67c[_0x1457('0x41')] === !![] && _0x3b51a9(_0x102df8(), _0x3171d7[_0x1457('0x40')])) _0x1e0cab = {
                'type': 0x3,
                'packageName': _0x2724db,
                'expiryDate': _0x33ff27(_0x4eb9e1[_0x1457('0x40')]),
                'docsUrl': _0x2a3029[_0x1457('0xb')]
              };else _0x2a1aff(_0x3a6131[_0x1457('0x18')], _0x30ed8b[_0x1457('0x40')]) && (_0x36826b = {
                'type': 0x2,
                'packageName': _0x2a7f61,
                'publishDate': _0x19d46e(_0x3f1d0c[_0x1457('0x18')]),
                'expiryDate': _0x18bd78(_0x3231eb[_0x1457('0x40')]),
                'docsUrl': _0x265a7c[_0x1457('0xb')]
              });
            }
          }
        }
      } else {
        if (_0x1457('0x30') !== _0x1457('0x7')) console[_0x1457('0x13')](_0x3e0d1b);else {
          function _0x135861() {
            _0x283aa0 += _0x1457('0x3c')[_0x1457('0x17')](_0x111d35[_0x1457('0x19')][_0x1457('0x1c')](), '.\x0a');
          }
        }
      }
      console[_0x1457('0x13')](formatError(_0x379487));
      if (_0x225671) {
        if (_0x1457('0xd') !== _0x1457('0xd')) {
          function _0x21b2c4() {
            _0x2b9264 = {
              'type': 0x1,
              'packageName': _0x346bb2,
              'docsUrl': _0x2e7146[_0x1457('0xb')]
            };
          }
        } else console[_0x1457('0xf')]();
      }
    }
  }
  var _0x415e0e = !_0x379487;
  return cache[_0x1457('0x34')](_0x401145[_0x1457('0xc')], _0x415e0e), _0x415e0e;
}
function formatError(_0x594e89) {
  var _0x2d7e52 = _0x1457('0x1d')[_0x1457('0x17')](_0x594e89[_0x1457('0x29')], '\x0a');
  if (_0x594e89[_0x1457('0xa')] === 0x2) {
    if (_0x1457('0x3f') !== _0x1457('0x1')) _0x2d7e52 += _0x1457('0x1f')[_0x1457('0x17')](_0x594e89[_0x1457('0x19')][_0x1457('0x1c')](), _0x1457('0x33'))[_0x1457('0x17')](_0x594e89[_0x1457('0x18')][_0x1457('0x1c')](), '.\x0a');else {
      function _0x4205ac() {
        _0x339f11 = {
          'type': 0x3,
          'packageName': _0x324919,
          'expiryDate': _0x301701(_0xaf8b40[_0x1457('0x40')]),
          'docsUrl': _0x5b91f9[_0x1457('0xb')]
        };
      }
    }
  }
  if (_0x594e89[_0x1457('0xa')] === 0x3) {
    if (_0x1457('0x11') === _0x1457('0x11')) _0x2d7e52 += _0x1457('0x3c')[_0x1457('0x17')](_0x594e89[_0x1457('0x19')][_0x1457('0x1c')](), '.\x0a');else {
      function _0x3e8c99() {
        _0x3f28ec += _0x1457('0x1f')[_0x1457('0x17')](_0x4667d4[_0x1457('0x19')][_0x1457('0x1c')](), _0x1457('0x33'))[_0x1457('0x17')](_0x23b5da[_0x1457('0x18')][_0x1457('0x1c')](), '.\x0a');
      }
    }
  } else {
    if (_0x594e89[_0x1457('0xa')] === 0x0) {
      if (_0x1457('0x35') === _0x1457('0x35')) _0x2d7e52 += _0x1457('0x28');else {
        function _0x3442b2() {
          _0x5ccb08 += _0x1457('0x3');
        }
      }
    } else {
      if (_0x594e89[_0x1457('0xa')] === 0x1) {
        if (_0x1457('0x26') !== _0x1457('0x26')) {
          function _0x23b1cd() {
            var _0x5bedaa = _0x465bb0(_0x2ba0db),
              _0x6c2ce2 = new _0x4a6023(_0x5bedaa[_0x1457('0x32')](), _0x5bedaa[_0x1457('0x4')](), _0x5bedaa[_0x1457('0x3d')]() + 0x1),
              _0x34ce8e = _0x6c2ce2[_0x1457('0x1e')]() / 0x3e8;
            return _0x2f1bc7 > _0x34ce8e;
          }
        } else _0x2d7e52 += _0x1457('0x3');
      }
    }
  }
  return _0x2d7e52 += _0x1457('0x23')[_0x1457('0x17')](_0x594e89[_0x1457('0xe')], _0x1457('0x2d')), _0x2d7e52;
}
function matchProduct(_0x408a16, _0x9b416b) {
  var _0x45ca78 = _0x408a16[_0x1457('0x2c')];
  if (!_0x45ca78 || !_0x45ca78[_0x1457('0x3e')]) {
    if (_0x1457('0x27') !== _0x1457('0x37')) return null;else {
      function _0x52a8d4() {
        return _0x25dc8b[_0x1457('0x2')](_0xcce210[_0x1457('0xc')]);
      }
    }
  }
  var _0x1880f2 = new Set(_0x9b416b);
  return _0x45ca78[_0x1457('0x36')](function (_0x226118, _0x16f928) {
    if (_0x1457('0x42') !== _0x1457('0x31')) return _0x16f928[_0x1457('0x40')] - _0x226118[_0x1457('0x40')];else {
      function _0x3dde91() {
        return null;
      }
    }
  })[_0x1457('0x12')](function (_0x4632fe) {
    if (_0x1457('0x3b') === _0x1457('0x3b')) return _0x1880f2[_0x1457('0x38')](_0x4632fe[_0x1457('0x1b')]);else {
      function _0x43b66d() {
        return _0x22688d[_0x1457('0x40')] - _0x522bbc[_0x1457('0x40')];
      }
    }
  });
}
function parseDate(_0xe99283) {
  return new Date(_0xe99283 * 0x3e8);
}
function getCurrentDate() {
  return new Date()[_0x1457('0x1e')]() / 0x3e8;
}
function isExpired(_0x4b71d2, _0x1df709) {
  var _0x4f97fc = parseDate(_0x1df709),
    _0xea6b1d = new Date(_0x4f97fc[_0x1457('0x32')](), _0x4f97fc[_0x1457('0x4')](), _0x4f97fc[_0x1457('0x3d')]() + 0x1),
    _0x302664 = _0xea6b1d[_0x1457('0x1e')]() / 0x3e8;
  return _0x4b71d2 > _0x302664;
}
export { setScriptKey, validatePackage };